import { createApp } from 'vue';
import { GlobalProperties } from 'o365-vue';
const singletonApps: { [key: string]: SingletonApp } = {};

/**
 * Singleton app maintainer class
 */
export default class SingletonApp {
    static #isInternalConstructing: boolean;

    /**
    * Get the singleton app with the provided options
    */
    static getOrCreateApp(options: ISingletonAppOptions) {
        if (!singletonApps[options.id]) {
            SingletonApp.#isInternalConstructing = true;
            singletonApps[options.id] = new SingletonApp(options);
            SingletonApp.#isInternalConstructing = false;
        }
        return singletonApps[options.id];
    }

    private _id: string;
    private _app: any;
    private _appContainer: HTMLElement;

    /**
     * Id of the vue app
     */
    get id() { return this._id; }

    /**
     * Root element this app is mounted on
     */
    get rootElement() {
        return this._appContainer;
    }

    /**
     * Should only be called by static SingletonApp factory methods
     */
    constructor(options: ISingletonAppOptions) {
        if (!SingletonApp.#isInternalConstructing) {
            throw new TypeError('SingletonApp is not constructable, use SingletonApp.getOrCreateApp()');
        }
        this._appContainer = document.createElement('div');
        this._appContainer.id = options.id;
        this._id = options.id;

        if (options.appendTo != null) {
            options.appendTo.appendChild(this._appContainer);
        } else {
            document.body.appendChild(this._appContainer);
        }

        this._app = createApp(options.appComponent, options.rootProps);

        this._app.use(GlobalProperties);

        if (options.provides) {
            Object.keys(options.provides).forEach((key: string) => {
                this._app.provide(key, options.provides![key]);
            })
        }
    }

    /**
     * Mount the app on the app container
     */
    mount() {
        this._app.mount(this._appContainer);
    }

    /**
     * Unmount and destroy the app instance
     */
    destroy() {
        this._app.unmount();
        this._appContainer.remove();
        delete singletonApps[this.id];
    }
}

interface ISingletonAppOptions {
    /** The id of the app */
    id: string;
    /** Component definition passed to the createApp function */
    appComponent: any;
    rootProps?: Record<string, any>,
    /** Element to which the app contianer will be appended. By default is document body */
    appendTo?: HTMLElement;
    /**Provides for the app */
    provides?: Record<string, any>,
}